.footer {
  background: #20202f;
  flex-direction: column;
  .trial-row {
    background-color: #262635;
  }
  .trial-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #262635;
    padding: 10px 0px;
    max-width: 1240px;
    margin:auto ;
    .trial-text-container {
      margin-top: 3rem;
      margin-bottom: 3rem;
      text-align: justify;
      width: 100%;
      margin-left: 10px;
      p:last-of-type {
        width: 80%;
      }
    }
    .trial-button-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      button {
        width: 30%;
        margin-right: 10px;
      }
    }
  }
  .footer-content {
    display: flex;
    flex-direction: column-reverse;
    padding: 50px 0px;
    justify-content: space-around;
    .footer-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-of-type(1) {
        padding: 0px 10px;
        text-align: justify;
        p {
          font-size: 5vw;
        }
      }
      &:nth-of-type(2) {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        .footer-subcolumn {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          a {
            text-decoration: none;
            margin: 10px;
          }
          p {
            margin: 10px;
            font-size: 3vw;
          }
        }
      }
      &:nth-of-type(3) {
        p {
          font-size: 6vw;
        }
      }
      .social-media-container {
        .social-media-icon {
          img {
            width: 30px;
            height: 30px;
            margin: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 425px) {
  .footer {
    min-height: 0 !important;
    .footer-content {
      .footer-column {
        &:nth-of-type(1) {
          p {
            font-size: 2vw;
          }
        }
        &:nth-of-type(2) {
          .footer-subcolumn {
            p {
              margin: 10px;
              font-size: 1.5rem;
            }
          }
        }
        &:nth-of-type(3) {
          p {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) {
  .footer {
    min-height: 0 !important;
    .trial-row {
      margin-bottom: 100px;
    }
    .trial-main-container {
      flex-direction: row;
      .trial-text-container {
        //padding-left: 10px;
        width: 70%;
        p:last-of-type {
          width: 80%;
        }
      }
      .trial-button-container {
        justify-content: center;
        align-items: center;
        width: 40%;
        img {
          margin: 2rem;
          max-width: 28rem;
          border-radius: 1.5rem;
        }
        button {
          width: 50%;
          margin-right: 0;
        }
      }
    }
    .footer-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      max-width: 1240px;
      width: 100%;
      margin: auto;
      p {
        font-size: 1vw;
      }
      .footer-column {
        max-width: 400px;
        flex-direction: row;
        &:nth-of-type(1) {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          p {
            font-size: 1.8rem;
          }
        }
        &:nth-of-type(2) {
          .footer-subcolumn {
            flex-direction: column;
            font-size: 1.4rem;
          }
        }
        &:nth-of-type(3) {
          flex-direction: column;
          justify-content: center;
          p {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
