$white:#FFFFFF;

$RedHighlight: #A724AA;

$Lila: #c5aac8;

$BlueMinded: #4466ff;

$Maroon6: #a324a3;

$Orange2: #ffb370;

$GreyWater: #5C5F58;

$LighterGrey: #DCE1E3;

$Dark : #162849;

$Blacked : #21212e;



$bgcol :$LighterGrey;

$color-initial: $LighterGrey;
$color-primary: $BlueMinded;
$color-secondary: $RedHighlight;
$color-tertiary: $Maroon6;
$color-quaternary: $Blacked;
$color-quinary: $Dark ;
