.features {
  flex-direction: column;
  position: relative;
  background: linear-gradient(white 10%, white 30%, $LighterGrey 90%);
  &.section {
    color: $Blacked;
  }
  &:after {
    content: "";
    width: 20vw;
    height: 20vw;
    position: absolute;
    bottom: 0;
    right: 2%;
    transform: translate(0, 60%);
    background: url("../images/features-square.png") no-repeat;
    background-size: contain;
    display: none;
  }
  .features-info-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
    .features-green {
      align-self: flex-start;
      background-color: #1e3639;
      color: #00ec98;
      width: 150px;
      padding: 10px;
      text-align: center;
    }
    h2 {
      margin-top: 10px;
      align-self: flex-start;
    }
    p {
      text-align: justify;
    }
    button {
      margin-top: 20px;
      width: 50%;
      height: 50px;
    }
  }
  .features-first-row {
    display: flex;
    flex-direction: column;
    margin-top: 150px;

    .right-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 50px;
      .features-card {
        width: 100%;
        padding: 0 0 0px 0px;
        background: $white;
        overflow: hidden;
        height: 100%;
        .feature-card--icon-container {
          background-color: transparent;
          padding: 5px 0px;
          display: flex;
          align-items: center;
          h4 {
            margin: 0;
            padding-right: 0.5rem;
          }
          img {
            width: 96px;
            height: 96px;
            margin-left: 0px;
          }
        }
        .feature-card--text-container {
          margin: 0px 20px;
          text-align: justify;
          p {
            font-size: 2rem;
            border-top: 1px solid #ffffff33;
            line-height: 1.4em;
            padding-top: 1rem;
          }
        }

      }
      .cards-column-left,
      .cards-column-right {

        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
        grid-auto-rows: minmax(100px, auto);
      }
    }
  }
  .features-second-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
    .left-column {
      //margin-bottom: 100px;
      height: auto;
      display: flex;
      justify-content: center;
      .appliances-card {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        .evening-mode-card,
        .consumption-chart-card {
          position: absolute;
          background-color: #20202c;
        }
        .evening-mode-card {
          top: 20%;
          transform: translateX(-25%);
          height: 20%;
          width: 80%;
          //background: #20202c url("../images/lightbulb-background.svg") no-repeat;
          background-size: contain;
          background-position: right;
          display: grid;
          grid-template-rows: 1fr 2fr 1fr;
          .row {
            padding-left: 10px;
            p {
              margin: 0;
              font-size: 5px;
            }
            &:nth-of-type(1) {
              margin-bottom: 20px;
              margin-top: 10px;
              p {
                font-size: 12px;
              }
            }
            &:nth-of-type(2) {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              p {
                &:nth-of-type(1) {
                  font-size: 13px;
                }
                &:nth-of-type(2) {
                  font-size: 7px;
                }
              }
            }
          }
        }
        .consumption-chart-card {
          top: 12%;
          right: 0;
          transform: translateX(30%);
          height: 28%;
          width: 55%;
          .title {
            display: flex;
            height: 20%;
            align-items: center;
            justify-content: center;
            background: #262634;
            font-size: 12px;
            margin-top: 10px;
          }
          .consumption-chart-container {
            display: flex;
            height: 80%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            //background: url("../images/chart.svg") no-repeat;
            background-size: 100px;
            background-position: center;
            p {
              margin: 0;
            }
          }
        }
        .top {
          height: auto;
          background: #1f151d;
          display: flex;
          justify-content: space-around;
          margin:20px ;
          width: 100%;
          padding: 10px;
          p {
            margin: 0;
          }
          .welcome-message-container,
          .hour-container {
            margin-top: 20px;
            align-self: center;
            justify-content: center;
            text-align: center;
          }
        }
        .bottom {
          display: flex;
          align-items: flex-end;
          height: 70%;
          .running-appliances-container {
            width: 100%;
            height: 70%;
            .running-appliances-title-container {
              height: 10%;
              font-size: 13px;
              padding: 0px 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .running-appliances-cards-container {
              height: 90%;
              width: 100%;
              display: flex;
              justify-content: center;
              .running-aplliance-card {
                height: 70%;
                display: grid;
                grid-template-rows: auto auto auto;
                // width: 45%;
                // max-width: 177px;
                margin: 10px;
                &:nth-of-type(2) {
                  align-self: flex-end;
                }
                .appliance-card-top {
                  display: flex;
                  justify-content: space-between;
                  .appliance-icon {
                    width: 50%;
                    margin: 10px;
                  }
                  .on-indicator {
                    align-self: flex-start;
                    width: 20%;
                  }
                }
                .appliance-card-middle {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  padding: 2px 2px;
                  background-color: #39394d;
                  p {
                    margin: 0;
                  }
                  .appliance-name {
                    font-size: 12px;
                  }
                  .appliance-on-time {
                    font-size: 10px;
                  }
                }
                .appliance-card-bottom {
                  img {
                    width: 15%;
                    margin: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


@media (min-width: 576px) {
  .features {
    .features-info-column {
      justify-content: flex-start;
      align-items: flex-start;
      p {
        max-width: 70%;
      }
      button {
        max-width: 200px;
      }
    }
    .features-first-row {
      .right-column {
       display: block;
      margin: 20px;
        .cards-column-left,
        .cards-column-right {

          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: minmax(100px, auto);
        }
        .cards-column-left {
          padding-bottom: 20px;
        }
        .cards-column-right {
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .features {
    .features-info-column {
      justify-content: center;
      width: 100%;
    }
    .features-first-row {
      flex-direction: column;
      max-width: 1080px;
      margin: auto;
      margin-top: 100px;


      .right-column {
        flex-direction: column;
        width: 100%;
        .features-card {
          max-width: 90%;
          margin: auto;
        }
        .cards-column-left,
        .cards-column-right {

          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-auto-rows: minmax(100px, auto);
        }
        .cards-column-left {
          margin-right: 0;
          padding-bottom: 20px;
        }
        .cards-column-right {
          padding-bottom: 20px;
        }
      }
    }
    .features-second-row {
      flex-direction: column;
      align-items: flex-start;
      max-width: 1080px;
      margin: auto;
      margin-top: 100px;
      .features-info-column {
        margin-left: 0px;
        width: 100%;
      }
      .left-column {
        width:100%;
      }
    }
  }
}
