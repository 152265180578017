.navigation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .logo-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    .website-logo {
      height: 50px;
      > * {
        fill:$Blacked
      }
    }
  }
  .navigation {
    width: 100%;
    display: flex;
    margin: 20px 0px;
    .nav-link {
      font-size: 4vw;
      color: #66667e;
      text-decoration: none;
      width: 100px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      &.with-border {
        border: 1px solid white;
      }
    }
  }
}
@media (min-width: 576px) {
  .navigation-container {
    height: 10rem;
    flex-direction: row;
    z-index: 10;
    position: relative;

    // width: 100%;

    .logo-container {
      width: 40%;
      justify-content: center;

    }
    .navigation {
      width: 50%;
      margin: 0;
      .nav-link {
        font-size: 20px;
        &:hover {
          color: white;
        }
      }
    }
  }
}

.svg-white {
  > * {
    fill:white
  }
}