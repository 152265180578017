

/* Slider */
#slider {
  width: calc(100vw - 2rem);
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: transparent;
  max-width: 1280px;
  height: 394px;
  box-shadow: 0px 0px 100px 5px #ffffff99;
  margin: 2rem auto;

  border-radius: 1.5rem;
  canvas {
    min-height: 100%;
    zoom: 1.2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0.66;
  }

  img {
    opacity: 0;
    width: 150%;
    //max-width: 1200px;

    max-height: none;
    max-width: none;
    position: absolute;
    z-index: 0;
    @media (min-width: 576px) {
      width: 120%;
    }
  }
}

.slider-inner {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 5;
  padding-bottom: 10%;
  max-width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  @media screen and (min-width: 1280px) {
    //max-width: 1060px;
  }
}

#slider-content {
  padding: 0 10px;

  h2 {
    font-family: 'Tinos', serif;
    font-size: 28px;
    letter-spacing: -1px;
    color: $Blacked;
    line-height: 28px;
    margin: 20px 0 10px;
    text-transform: uppercase;
    font-weight: 600;
    @media screen and (min-width: 800px) {
      font-size: 40px;
      line-height: 41px;
      margin-bottom: 15px;
    }
    @media screen and (min-width: 1040px) {
      font-size: 60px;
      line-height: 60px;
    }
  }

  span {
    display: none;
  }

  .meta {
    display: inline-block;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 5px;
    color: $BlueMinded;
    text-transform: uppercase;
    position: relative;

    @media screen and (min-width: 800px) {
      font-size: 13px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      right: -55px;
      width: 45px;
      height: 2px;
      background-color: #393d40;
    }
  }

  #slide-status {
    margin-top: 10px;
    font-family: 'Tinos', serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    color: $Blacked;
    text-shadow: 1px 1px 4px $LighterGrey,0px 0px 12px $LighterGrey;

    @media screen and (min-width: 992px) {

      width: 70%;
      font-size: 24px;
    }
  }
}

#pagination {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-00px);
  right: 0;
  z-index: 6;
  display: flex;
  width: 80px;
  justify-content: space-between;
  flex-direction: column;
  &::before {
    content:"";
    position: absolute;
    width: 100vw;
    right: calc(100% + 1rem);
    height: 1px;
    background: $LighterGrey;
    display: block;
    top: 50%;
    display: none;

  }

  button {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    width: 16px;
    height: 16px;
    background-color: $Blacked;
    border-radius: 100%;
    padding: 0;
    margin: 30px 0;
    cursor: pointer;
    position: relative;
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;
    outline: none;

    &:hover {
      opacity: 0.5;
    }

    &.active {
      opacity: 1;
      background-color: $BlueMinded;

      &:before {
        width: 300%;
        height: 300%;
        opacity: 1;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 1px solid rgba(255, 255, 255, 0.2);
      opacity: 0;
      transition: opacity 0.4s ease-in-out, width 0.4s ease-in-out, height 0.4s ease-in-out;
    }
  }
}

/* Page Loader */
.loading {

  &:before {
    content: '';
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
  }

  &:after {
    content: '';
    position: fixed;
    z-index: 100000;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    pointer-events: none;
    border-radius: 50%;
    opacity: 0.4;
    background: white;
    animation: loaderAnim 0.7s linear infinite alternate forwards;
  }
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5,0.5,1);
  }
}
@media (min-width: 576px) {
  #slider {
    width: calc(100vw - 5rem);
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background: transparent;
    max-width: 1280px;
    height: min(70vh,400px);
    box-shadow: 0px 0px 100px 5px #ffffff99;
    canvas {

      opacity:1;
    }
  }
}

@media (min-width: 992px) {
  #slider {
    width: calc(100vw - 20rem);
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background: transparent;
    max-width: 1280px;
    height: min(calc(100% - 20rem) ,400px);
    box-shadow: 0px 0px 100px 5px #ffffff99;
    padding-bottom: 50%;
  }
}
@media (min-width: 1480px) {
  #slider {
    padding-bottom:  max(80vh, 600px);
    canvas {

      zoom: 1.1;}
  }
}
@media (min-width: 1680px) {
  #slider {
    padding-bottom:  max(80vh, 600px);
    canvas {

      zoom: 1.25;}
  }
}

