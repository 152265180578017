.hero {
  position: relative;
  .hero-main-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    .text-container {
      position: relative;
      padding: 0 10px;
      h1 {
        font-size: 9vw;
        font-weight: lighter;
        margin-bottom: 30px;
      }
      p {
        font-size: 4vw;
        line-height: 25px;
        font-weight: 100;
        text-align: justify;
      }
      &:after {
        content: "";
        position: absolute;
        top: -15%;
        right: 5%;
        width: 20vw;
        height: 20vw;
        z-index: -1;
        background-size: contain;
      }
    }
    .buttons-container {
      display: flex;
      width: 100%;
      margin-top: 50px;
      button {
        width: 40%;
        a{
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
        &.colored {
          width: 60%;
          box-shadow: 0 0 3px #822eff;
          margin-right: 20px;
        }
      }
    }
  }
  .background-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-size: cover;
    background-position: center;
  }
}
@media (orientation: landscape) {
  .hero {
    .hero-main-container {
      .text-container {
        h1 {
          font-size: 5vw;
        }
        p {
          font-size: 3vw;
        }
      }
    }
  }
}
@media (min-width: 600px) {
  .hero {
    .hero-main-container {
      .text-container {
        width: 50%;
        h1 {
          font-size: 4.5vw;
        }
        p {
          font-size: 2.5vw;
          width: 80%;
        }
        &:after {
          top: -35%;
          right: -20%;
          width: 15vw;
          height: 15vw;
        }
      }
      .buttons-container {
        width: 50%;
      }
    }
    .background-container {
          right: 0;
      width: 65%;
      height: 80%;
    }
  }
}
@media (min-width: 768px) {
  .hero {
    .hero-main-container {
      padding-left: 50px;
      .text-container {
        h1 {
          font-size: 4.5vw;
        }
        p {
          font-size: 19px;
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .hero {
    .hero-main-container {
      .text-container {
        h1 {
          font-size: 51px;
        }
        &:after {
          width: 10vw;
          height: 10vw;
        }
      }
      .buttons-container {
        width: 40%;
      }
    }
    .background-container {
      width: 65%;
      height: 90%;
    }
  }
}
@media (min-width: 1200px) {
  .hero {
    .hero-main-container {
      .text-container:after{
        right: 0;
      }
    }
  }
}
