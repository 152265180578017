.section#propo-section {
  color: $Blacked;
  margin: 0 auto;
  max-width: 1060px;
  padding: 0px ;
  flex-direction: column;
  justify-content: center;
  min-height: auto;
}
.propos {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 50vw;
    height: 50vw;
    background: url("../images/parterns_king.png") no-repeat;
    background-size: cover;
    bottom: 0;
    right: 0;
    transform: translate(50%, 100%);
    z-index: -1;
  }
  .right-column {
    width: 100%;

    .propos-card {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      padding: 0.8rem;
      text-transform: uppercase;
      font-size: 1.8rem;
      text-align: center;
    }
    .row {
      display: flex;
      justify-content: flex-start;
      height: auto;



    }
  }
  .left-column {
    width: 100%;
    padding: 0px 20px;
    p {
      text-align: justify;
    }
  }
}
@media (min-width: 540px) {
  .propos {
    &:before {
    width: 20vw;
    height: 20vw;
    transform: translateY(-70%);
    }
    &:after {
    width: 40vw;
    height: 40vw;
    transform: translate(50%, 80%);
    }
    .right-column {
      .partner-card {
        margin: 10px;
      }
      .row {
        height: auto;


      }
    }
    .left-column {
      padding: 0px 20px;
      h2{
        //width: 60%;
      }
      p {
        //width: 80%;
      }
    }
  }
}
@media (min-width: 992px) {
  .propos {
    &:before {
      width: 15vw;
      height: 15vw;
      transform: translateY(0%);
      }
      &:after {
      width: 25vw;
      height: 22vw;
      transform: translate(50%, 50%);
      }
    .right-column {
      width: 100%;
    }
    .left-column {
      width: 100%;
      text-align: center;
      p {
        width: 80%;
        text-align: justify;
      }
    }
  }
}

